/* Globals */

@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Sofia Sans', 'Segoe UI', Arial, sans-serif;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  color: black;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

th,
td {
  color: black !important;
}

.bg-primary-light {
  background-color: #d6defa;
}

.bg-success-light {
  background-color: #d5f0e6;
}

.bg-info-light {
  background-color: #d4e5f0;
}

.bg-warning-light {
  background-color: #fdf2df;
}

.bg-danger-light {
  background-color: #f8d4d5;
}

.bg-black {
  background-color: #000000;
}

.bg-pink {
  background-color: #d63384;
}

/* Main Globals */

main {
  flex: 1;
  display: flex;
}

/* Nav */

.navbar {
  background: #000;
}

.navbar-dark .navbar-nav .nav-link.active {
  background: #000;
  color: var(--bs-nav-link-color);
}

.navbar-dark .navbar-nav .nav-link.active:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--bs-primary);
}

.navbar-dark .navbar-nav .setting.active:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--bs-primary);
}

.navbar .nav-link {
  cursor: pointer;
}

/* Footer */

footer {
  padding: 12px 0;
  background: #000;
  color: #fff;
  text-align: center;
}

/* Home */

#home {
  flex-direction: column;
}

#home .hero {
  flex: 1;
}

#home .hero img {
  width: 100%;
}

#home .display-1.icon {
  font-size: 200px;
}

/* Login */

#login {
  flex: 1;
  align-items: center;
}

/* Dashboard */

#dashboard {
  flex: 1;
  align-items: center;
}

.form-floating .form-control {
  position: relative;
}

.form-floating.show .form-control {
  border-radius: 0.375rem 0.375rem 0 0;
}

.autocomplete {
  position: absolute;
  top: 57px;
  left: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
  width: 100%;
  z-index: 3;
  border: 1px solid #ced4da;
  border-radius: 0 0 0.375rem 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
  height: 300px;
  overflow-y: auto;
}

.autocomplete.hide {
  display: none;
}

.autocomplete.show {
  display: block;
}

.autocomplete li {
  padding: 8px;
}

.autocomplete li.active {
  background: #eeeeee;
}

.autocomplete li:hover {
  background: #eeeeee;
}

#dashboard .card {
  flex: 1;
}

#dashboard .card.orders {
  height: 100%;
}

/* Order */

#order {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

#order form {
  flex: 1;
  display: flex;
  overflow-y: auto;
}

#order section {
  flex: 3;
  overflow-y: auto;
}

.card-img-top {
  height: 96px;
}

#order aside {
  flex: 1;
  overflow-y: auto;
}

#order aside>div.items {
  flex: 1;
  overflow-y: auto;
}

#order .btn-group {
  position: absolute;
  bottom: 0;
  right: 0;
}

#order .swap-x:hover {
  color: #b92327;
  cursor: pointer;
}

.driver-icon {
  width: 60px;
  height: 60px;
  font-size: 32px;
  line-height: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Checkout */

#checkout form {
  flex: 1;
  display: flex;
}

#checkout form>div:first-child {
  flex: 2;
}

#checkout form>div:last-child {
  flex: 1;
}

/* Dispatch */

.tabular,
#dispatch,
#categories,
#category,
#customers,
#campaign,
#campaigns,
#products,
#deals,
#drivers,
#orders,
#expenses,
#permissions {
  flex-direction: column;
}

#dispatch .card {
  flex: 1;
  height: 100%;
}

#dispatch .accordion-item {
  flex: 1;
}

#dispatch option {
  text-transform: capitalize;
}

.card.position-relative .card-body {
  height: 175px !important;
}

.card.position-relative img {
  width: 100% !important;
  height: 100% !important;
}

.reset {
  display: none;
  position: absolute;
  top: 12px;
  right: 20px;
}

.card.position-relative:hover .reset {
  display: block;
}

.fa-share {
  transform: scaleX(-1);
}

#expenses td a,
.page-link {
  cursor: pointer;
}

.badge-success-light {
  background-color: #d5f0e6;
}

.badge-danger-light {
  background-color: #f8d4d5;
}

/* Summary */

.summary-table .card-body {
  height: 300px;
  overflow-y: auto;
}

.deal-image-container {
  background: rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
}

.deal-image {
  height: 10rem;
}

.additional {
  color: red;
  font-size: 12px;
}

.select {
  margin: 0;
  padding: 0;
  width: 20rem;
}

.table-head {
  display: flex;
}

/* Video */

.video {
  width: 100%;
  height: 450px;
  border-radius: 0.375rem;
}

/* Driver */

.driver-status {
  width: 10rem;
}

@media print {
  .no-print {
    display: none;
  }
}

/* Complaint */

#complaint textarea {
  height: 200px !important;
}